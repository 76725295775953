<template>
  <div class="comment-list">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <template #default>
        <div class="comment">
          <div v-for="item in commentList" :key="item.comment_id" class="post-comment-list-body">
            <div class="post-comment-list-body-item">
              <div class="list-item-logo-name-time">
                <van-image class="user-logo" round fit="cover" :src="item.user_info.avatar || defaultImg" />
                <div class="user-name">
                  {{ item.user_info ? item.user_info.nickname : "" }}
                </div>
              </div>
              <div class="list-item-content">{{ item.comment_content }}</div>
              <template v-if="item.parent_comment">
                <div class="list-item-parent-content">
                  <span class="parent-name">{{ item.parent_comment.nickname }}</span>
                  :{{ item.parent_comment.parent_comment_content }}
                </div>
              </template>
              <template v-else>
                <div class="list-item-parent-content"
                  @click="$router.push({ path: '/post', query: { id: item.post_article_info.post_article_id }, })">
                  <div class="parent-name"> {{ item.post_article_info.nickname }}:<div class="parent-article-content"
                      v-html="item.post_article_info.post_article_content"></div>
                  </div>
                </div>
              </template>

              <div class="list-item-bottom">
                <div class="time">{{ item.create_time }}</div>
                <div class="list-item-action" v-show="showBottom">
                  <div class="like" @click.stop="handleCommentLike(item)">
                    <img v-if="!item.is_like" src="~@/assets/images/like.svg" alt />
                    <img v-else src="~@/assets/images/like-active.svg" alt />
                    {{ item.comment_like_count }}
                  </div>
                  <div>
                    <img src="~@/assets/images/reply.svg" alt />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </van-list>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'CommentAtricle',
  props: {
    commentList: {
      type: Array,
      default: []
    },
    showBottom:{
      type:Boolean,
      default:true
    }

  },
  data() {
    return {
      loading:false,
      finished: false,
      defaultImg: require('@/assets/images/userLogo.jpg'),
    };
  },
  computed: {
    ...mapState(['token']),

  },
  mounted() {

  },

  methods: {

    //  评论点赞
    async handleCommentLike(comment) {
      console.log(123,comment);
      // 当用户已经登录时
      if (this.token) {
        // 当前帖子的id
        const comment_id = comment.comment_id;
        let is_like = comment.is_like;
        // 调用点赞接口
        await this.api.
          postFormAPISM(
            {
              post_article_comment_id: comment_id,
              is_like: !is_like,
            },
            "/article/commentlike"
          );

        // 切换是否点赞
        comment.is_like = !comment.is_like;
        //  重新获取评论列表,刷新评论列表
        this.$emit('getAllCommentList')
      }else {
          // 未登录，跳转回登录页面
          this.$router.push('/login');
        }
  
    },
    onLoad()
    {
      this.$emit('getMoreCommentList');
    }
  }
}
  </script>
  
<style lang="less" scoped>
.comment {
  padding: 0 18px;
  overflow: hidden;

  .post-comment-list-body {
    display: flex;
    flex-flow: column nowrap;

    &:last-child {
      margin-bottom: 30px;
      // 62PX
    }

    &>.post-comment-list-body-item {
      padding: 15px 0 10px;
      border-top: 1px solid #edf0f5;

      &>.list-item-logo-name-time {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        &>.user-logo {
          width: 35px;
          height: 35px;
        }

        &>.user-name {
          flex: 1;
          font-size: 15px;
          color: #33353c;
          font-weight: 700;
          padding: 0 0 0 10px;
        }

        &>.ellipsis {
          width: 20px;
          text-align: right;
          font-size: 12px;
          color: #909499;
        }



      }

      &>.list-item-content {
        padding: 0 0 0 45px;
        font-size: 14px;
        color: #33353c;
        line-height: 24px;
      }

      &>.list-item-parent-content {
        background-color: #f1f1f1;
        margin: 0 0 0 45px;
        font-size: 13px;
        color: #a7a6a6;
        padding: 15px;
        border-radius: 8px;

        & /deep/ .parent-article-content {
          color: #33353c;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;

          & p,
          span,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            background-color: #f1f1f1 !important;
          }

          & img {
            width: 100%;
          }
        }

        .parent-name {
          color: #27a1ff;
          font-size: 13px;

        }
      }

      &>.list-item-bottom {
        padding: 10px 0 0 45px;
        display: flex;
        // flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;

        &>.list-item-action {
          display: flex;
          // flex-flow: row nowrap;
          align-items: center;

          // padding: 8px 0 0 42px;
          &>div {
            display: flex;
            align-items: center;
            // height: 18px;
            padding-right: 14px;
            cursor: pointer;

            &:not(:first-child) {
              padding-left: 14px;
              border-left: 1px solid rgba(217, 217, 217, 0.6);
            }
          }

          img {
            width: 15px;
            height: 15px;
            margin-right: 8px;
          }



          &>svg {
            width: 16px;
            height: 16px;

            &.reply {
              width: 14px;
              height: 14px;
            }
          }

          &>span {
            font-size: 13px;
            color: #909499;
            padding: 0 0 0 8px;
          }
        }
      }
    }
  }
}

.no-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 100px 0;

  // background-color:#f7f7f7;
  .no-comment-text {
    color: #a7a6a6;
    font-size: 16px;
  }
}</style>