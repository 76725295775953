<template>
    <div class="userRecord-box">
        <van-nav-bar class="nav-bar" left-arrow @click-left="$router.back()" />
        <div class="tabs">
            <van-tabs v-model="active" color="#323233" animated @click="clickTabs">
                <van-tab title="收藏" name="0">
                    <Aticles ref="aticlesCollectRef" :isShowCross="false" v-if="collectArticleList.length !== 0"
                        :aticleList="collectArticleList" @getPostList="clickTabs" @getMoreCollectList="getMoreCollectList"
                        :isShowFollowBtn="false" />
                    <EmptyContent v-if="collectArticleList.length === 0" description="暂无收藏，快来收藏吧" />
                </van-tab>
                <van-tab title="评论" name="1">
                    <CommentAtricle ref="aticlesCommentRef" :showBottom="false" :commentList="commentArticleList"
                        @getMoreCommentList="getMoreCommentList" v-if="commentArticleList.length !== 0" />
                    <EmptyContent v-else description="暂无评论" />
                </van-tab>
                <van-tab title="点赞" name="2">
                    <Aticles ref="aticlesLikeRef" :isShowCross="false" v-if="likeArticleList.length !== 0"
                        :aticleList="likeArticleList" @getPostList="clickTabs" @getMoreLikeList="getMoreLikeList"
                        :isShowFollowBtn="false" />
                    <EmptyContent v-else />
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
import Aticles from "@/components/Mobile/Aticles"
// 空状态
import EmptyContent from "@/components/Mobile/EmptyContent"
//评论列表
import CommentAtricle from '@/components/Mobile/CommentAtricle'
export default {
    name: 'UserRecord',
    components: {
        Aticles,
        EmptyContent,
        CommentAtricle,
    },
    data() {
        return {
            active: 0,
            // 收藏文章列表
            collectArticleList: [],
            // 点赞文章列表
            likeArticleList: [],
            //评论列表
            commentArticleList: [],
        };
    },
    created() {

        // console.log(this.active);
    },
    activated() {
        this.active = this.$route.query.actives;
        this.clickTabs();
        

    },

    methods: {
        // 点击tab栏获取相应的数据
        async clickTabs() {
            // 获取收藏的文章列表
           
            if (this.active == 0) {
                // 防止出现首次加载情况
                if(this.$refs.aticlesCollectRef)
                {
                    this.$refs.aticlesCollectRef.finished = false;
                }
                
                try {
                    
                    let { data: res } = await this.api
                        .postFormAPISM(
                            {
                                limit: 20,
                                start_time: ""
                            },
                            "/article/getcollectionarticlelist"
                        )
                        
                    let temp = res.data.article_info_list;
                    // 格式化内容
                    this.collectArticleList=this.FormatContent(temp);
                    // this.loading = false;
                } catch (error) {
                    console.log(error);

                }
            }
            // 获取评论列表
            else if (this.active == 1) {
                try {
                    let { data: res } = await this.api
                        .postFormAPISM(
                            {
                                limit: 20,
                                start_time: ""
                            },
                            "/article/getarticlecommentlist"
                        )
                    let temp = res.data.comment_article_info_list;
                    //   格式化时间
                    this.commentArticleList = this.FormatTimes(temp);
                } catch (error) {
                    console.log(error);
                }
            }
            // 获取点赞的文章列表
            else {
                // 防止出现首次加载情况
                if(this.$refs.aticlesLikeRef)
                {

                    this.$refs.aticlesLikeRef.finished = false;
                }
                try {
                    let { data: res } = await this.api
                        .postFormAPISM(
                            {
                                limit: 20,
                                start_time: ""
                            },
                            "/article/getlikearticlelist"
                        )
                       
                        let temp   = res.data.article_info_list;
                         //格式化时间
                    this.likeArticleList = this.FormatTimes(temp);
                } catch (error) {
                    console.log(error);

                }
            }
        },
        // 加载更多收藏内容
        async getMoreCollectList() {
             // 如已经加载完成就不用继续下去
        if(this.$refs.aticlesCollectRef.finished) return;
            try {
                let { data: res } = await this.api
                    .postFormAPISM(
                        {
                            limit: 20,
                            start_time: this.collectArticleList[this.collectArticleList.length - 1].create_time
                        },
                        "/article/getcollectionarticlelist"
                    )
                let arr = res.data.article_info_list;
                // 格式化内容
                arr=this.FormatContent(arr);
                this.collectArticleList = [...this.collectArticleList, ...arr];
                // 判断是否所有的数据全部加载完成，如果是：finished设为true
                if (arr.length === 0) {
                    // 说明取不到数据，加载完成，finish设置为true
                    this.$refs.aticlesCollectRef.finished = true;
                }
            } catch (error) {
                console.log(error);
            }
        },
        // 加载更多，加载更多点赞内容
        async getMoreLikeList() {
            // 如已经加载完成就不用继续下去
        if(this.$refs.aticlesLikeRef.finished) return;
            try {
                let { data: res } = await this.api
                    .postFormAPISM(
                        {
                            limit: 20,
                            start_time: this.likeArticleList[this.likeArticleList.length - 1].create_time
                        },
                        "/article/getlikearticlelist"
                    )
                let arrs = res.data.article_info_list;
                   //格式化时间
                  arrs = this.FormatTimes(arrs);
                this.likeArticleList = [...this.likeArticleList, ...arrs];
                // 判断是否所有的数据全部加载完成，如果是：finished设为true
                if (arrs.length === 0) {
                    // 说明取不到数据，加载完成，finish设置为true
                    this.$refs.aticlesLikeRef.finished = true;
                }
            } catch (error) {
                console.log(error);
            }
        },
         // 加载更多评论
      async  getMoreCommentList()
        {       
            try {
                let { data: res } = await this.api
                    .postFormAPISM(
                        {
                            limit: 20,
                            start_time: this.commentArticleList[this.commentArticleList.length - 1].create_time
                        },
                        "/article/getarticlecommentlist"
                    )
                let arrs = res.data.comment_article_info_list;
                //格式化时间
                arrs=this.FormatTimes(arrs);
                this.commentArticleList = [...this.commentArticleList, ...arrs];
                // 把loading设置为false
                this.$refs.aticlesCommentRef.loading = false;
                // 判断是否所有的数据全部加载完成，如果是：finished设为true
                if (arrs.length === 0) {
                    // 说明取不到数据，加载完成，finish设置为true
                    this.$refs.aticlesCommentRef.finished = true;
                }
            } catch (error) {
                console.log(error);
            }
        },
        // 格式化时间
        FormatTimes(list) {
            list.forEach(item => {
                item.create_time =  this.dayjs(item.create_time).format('YYYY-MM-DD');
            });
            return list;
        },
        // 格式化内容
        FormatContent(list)
        {
            list=  list.map(item=>{
                //格化化时间
                    item.create_time=this.dayjs(item.create_time).format('YYYY-MM-DD');
                    // 收藏内容若为新闻，则去掉新闻内容中的换行
                    if (item.author) {
                        item.content = this.replaceImage(item.content, item.article_id);
                    }
                    return {
                        content:item.content,
                        ...item,
                    };
                })
                return list;
        },
        // 去掉新闻内容中的换行
        replaceImage(content, id) {
            let reg =
                /({{[^}}]+.(bmp|jpg|png|tif|gif|pcx|tga|exif|fpx|svg|psd|cdr|pcd|dxf|ufo|eps|ai|raw|WMF|webp|avif|apng)}})/g;
            let reg2 = /^{{([^}}]+)}}$/;
            let length = 300;
            let temp = content.replace(/<br>|\s/g, "");
            // console.log('replaceImage', content.match(/<br>/g));
            let tempImage = temp.match(reg) || [];
            // tempImage.length && console.log("tempImage", id);
            for (let i = 0; i < tempImage.length; i++) {
                let url = reg2.exec(tempImage[i]);
                if (url instanceof Array) {
                    url = url[1];
                    temp = temp.replace(tempImage[i], "");
                    // temp = temp.replace(tempImage[i], imageTemplate(ImageBasicUrl + url));
                }
            }
            return temp;
        },
    }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>